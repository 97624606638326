import React from 'react'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'

import {Content} from '../components/content'
import {Layout} from '../components/grid'
import {Header} from '../components/header'
import {Questions} from '../components/questions'

interface PageData{
  rootQuestion: {
    title: string
    description: string
  }

  questions: {
    nodes: {
      title: string
      description: string
      fields: {
        slug: string
      }
    }[]
  }
}

const IndexPage: React.FC<{data: PageData}> = ({data}) => {
  const {rootQuestion, questions: {nodes: questions}} = data

  return <>
    <Helmet title="Ed-IT Solutions Self Help" />
    <Header />
    <Layout>
      <h1>{rootQuestion.title}</h1>
      <Content html={rootQuestion.description} />
      <Questions questions={questions} />
    </Layout>
  </>
}

export default IndexPage

export const PageQuery = graphql`
  query IndexPageQuery {
    rootQuestion: directusQuestion(directusId: {eq: 1}) {
      title
      description
    }

    questions: allDirectusQuestion(sort: {fields: title}, filter: {directusParent: {id: {eq: 1}}}){
      nodes{
        title
        description
        fields{
          slug
        }
      }
    }
  }
`